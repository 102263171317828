<template>
  <div class="row">
    <div class="col-12 col-lg-3">
      <div class="card">
        <div class="d-flex flex-column align-items-center mt-5 mb-4">
          <img
            width="100"
            height="100"
            class="rounded"
            :src="
              state.akun.avatar
                ? state.backendUrl + state.akun.avatar
                : `https://ui-avatars.com/api/?background=3e4095&color=fff&name=${state.akun
                    .firstName +
                    ' ' +
                    (state.akun.lastName ? state.akun.lastName : '')}`
            "
            alt=""
          />
          <h5 class="mt-3">
            {{ state.akun.firstName + ' ' + (state.akun.lastName ? state.akun.lastName : '') }}
          </h5>
          <p class="text-secondary">{{ state.akun.email || '-' }}</p>
        </div>
        <ul class="list-unstyled mb-0">
          <li
            v-for="menu in menus"
            :key="menu.name"
            @click.prevent="onChangeMenu(menu.name)"
            :class="[$style.item, state.menuActive === menu.name ? $style.active : '']"
          >
            <div :class="$style.itemLink">
              <div :class="$style.itemPic">
                <i :class="menu.icon" />
              </div>
              <div class="mr-2">
                <div class="">{{ menu.name }}</div>
                <!-- <div class="text-muted">{{ menu.total }} {{ menu.addition }}</div> -->
              </div>
              <div :class="$style.itemAction">
                <span />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <a-button style="margin-right: 5px;" @click="handleClickBack"><LeftOutlined /></a-button>
          <h5 style="margin-bottom: 0;">Detail Post</h5>
        </div>
        <div class="card-body mt-2" v-if="loading">
          <div>Loading...</div>
        </div>
        <div class="card-body mt-2" v-else>
          <!-- <AccountDetails v-if="state.menuActive === 'Detail Akun'" :fetch-detail="fetchDetail" />
          <daftar-post v-if="state.menuActive === 'Daftar Post'" />
          <daftar-topup
            v-if="state.menuActive === 'Daftar Deposit/Withdrawal'"
            :user-id="state.akun._id"
          />
          <daftar-transaksi
            v-if="state.menuActive === 'Daftar Transaksi'"
            :user-id="state.akun._id"
          /> -->

          <a-form layout="vertical" v-if="typePost === 'content'">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="Thumbnail">
                  <img
                    :src="`${URL}/${selectedContent?.thumbnail}`"
                    :alt="selectedContent?.sentence"
                    class="img-thumbnail"
                  />
                </a-form-item>
                <a-form-item label="Username Creator">
                  <a-input placeholder="Username" :value="selectedContent?.creator.username" />
                </a-form-item>
                <a-form-item label="Price">
                  <a-input placeholder="Price" :value="selectedContent?.price" />
                </a-form-item>
                <a-form-item label="Sentence">
                  <a-input placeholder="Sentence" :value="selectedContent?.sentence" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="Contents">
                  <a-carousel
                    dots-class="slick-dots slick-thumb slick-dots-to-bottom"
                    v-if="selectedContent?.attachments.length"
                  >
                    <template #customPaging="props">
                      <a v-if="selectedContent?.attachments[props.i].type === 'image'">
                        <img
                          :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                          :alt="selectedContent?.attachments[props.i].caption"
                          style="height: 25px ;"
                        />
                        <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                        <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                        <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                      </a>
                      <a v-else-if="selectedContent?.attachments[props.i].type === 'video'">
                        <video style="height: 25px;">
                          <source
                            :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    </template>
                    <div v-for="item in selectedContent?.attachments" :key="item.id">
                      <div
                        style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                      >
                        <a-image
                          :src="`${URL}/${item.url}`"
                          :alt="item.caption"
                          style="height: 150px; width: 250px;"
                          v-if="item.type === 'image'"
                        />
                        <video
                          v-else-if="item.type === 'video'"
                          style="height: 150px; width: 250px;"
                          controls
                        >
                          <source :src="`${URL}/${item.url}`" type="video/mp4" />
                        </video>
                        <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                      </div>
                    </div>
                  </a-carousel>
                  <div v-else>Empty Content</div>
                </a-form-item>
                <a-form-item label="Comments">
                  <div v-if="selectedContent?.lastComments.length">
                    <div v-for="(item, index) in selectedContent?.lastComments" :key="index">
                      <div style="margin-bottom: 15px;">
                        <div style="display: flex; align-items: center; margin-bottom: 5px;">
                          <a-avatar
                            :src="`${URL}/${item.creator.avatar_sm}`"
                            v-if="item.creator.avatar_sm"
                          />
                          <a-avatar
                            :src="`${URL}/${item.creator.avatar}`"
                            v-else-if="item.creator.avatar"
                          />
                          <a-avatar v-else>
                            <template #icon><UserOutlined /></template
                          ></a-avatar>
                          <p
                            style="margin-left: 5px; font-weight: 700; text-align: center;margin-bottom: 0;"
                          >
                            {{ item.creator.username }}
                          </p>
                        </div>
                        <p style="margin-bottom: 0;">
                          {{ item.comment }}
                        </p>
                        <div
                          style="background-color: black; width: 100%; height: 1px;margin-top: 5px;"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    Empty Comment
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <a-form layout="vertical" v-else-if="typePost === 'story'">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="Username Creator">
                  <a-input placeholder="Username" :value="selectedStory?.creator.username" />
                </a-form-item>
                <a-form-item label="Restory Content">
                  <a-carousel
                    dots-class="slick-dots slick-thumb"
                    v-if="selectedStory?.restoryContent"
                  >
                    <template #customPaging="props">
                      <a v-if="selectedStory?.restoryContent.attachments[props.i].type === 'image'">
                        <img
                          :src="`${URL}/${selectedStory?.restoryContent.attachments[props.i].url}`"
                          :alt="selectedStory?.restoryContent.attachments[props.i].caption"
                          style="height: 25px ;"
                        />
                        <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                        <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                        <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                      </a>
                      <a
                        v-else-if="
                          selectedStory?.restoryContent.attachments[props.i].type === 'video'
                        "
                      >
                        <video style="height: 25px;">
                          <source
                            :src="
                              `${URL}/${selectedStory?.restoryContent.attachments[props.i].url}`
                            "
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    </template>
                    <div v-for="item in selectedStory?.restoryContent.attachments" :key="item.id">
                      <div
                        style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                      >
                        <a-image
                          :src="`${URL}/${item.url}`"
                          :alt="item.caption"
                          style="height: 150px; width: 250px;"
                          v-if="item.type === 'image'"
                        />
                        <video
                          v-else-if="item.type === 'video'"
                          style="height: 150px; width: 250px;"
                          controls
                        >
                          <source :src="`${URL}/${item.url}`" type="video/mp4" />
                        </video>
                        <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                      </div>
                    </div>
                  </a-carousel>
                  <!-- <a-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory?.restoryContent"
              >
                <template #customPaging="props">
                  <a>
                    <img
                      :src="`${URL}/${selectedStory?.restoryContent.attachments[props.i].url}`"
                      :alt="selectedStory?.restoryContent.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                  </a>
                </template>
                <div v-for="item in selectedStory?.restoryContent.attachments" :key="item.id">
                  <div style="display: flex;flex-direction: column;">
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 200px ;"
                    />
                    <p style="text-align: center;margin-bottom: 0;">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel> -->
                  <div v-else>Empty Restory Content</div>
                </a-form-item>
                <a-form-item label="Restory Story">
                  <a-carousel
                    dots-class="slick-dots slick-thumb"
                    v-if="selectedStory?.restoryStory"
                  >
                    <template #customPaging="props">
                      <a v-if="selectedStory?.restoryStory.attachments[props.i].type === 'image'">
                        <img
                          :src="`${URL}/${selectedStory?.restoryStory.attachments[props.i].url}`"
                          :alt="selectedStory?.restoryStory.attachments[props.i].caption"
                          style="height: 25px ;"
                        />
                        <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                        <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                        <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                      </a>
                      <a
                        v-else-if="
                          selectedStory?.restoryStory.attachments[props.i].type === 'video'
                        "
                      >
                        <video style="height: 25px;">
                          <source
                            :src="`${URL}/${selectedStory?.restoryStory.attachments[props.i].url}`"
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    </template>
                    <div v-for="item in selectedStory?.restoryStory.attachments" :key="item.id">
                      <div
                        style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                      >
                        <a-image
                          :src="`${URL}/${item.url}`"
                          :alt="item.caption"
                          style="height: 150px; width: 250px;"
                          v-if="item.type === 'image'"
                        />
                        <video
                          v-else-if="item.type === 'video'"
                          style="height: 150px; width: 250px;"
                          controls
                        >
                          <source :src="`${URL}/${item.url}`" type="video/mp4" />
                        </video>
                        <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                      </div>
                    </div>
                  </a-carousel>
                  <!-- <a-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory?.restoryStory"
              >
                <template #customPaging="props">
                  <a>
                    <img
                      :src="`${URL}/${selectedStory?.restoryStory.attachments[props.i].url}`"
                      :alt="selectedStory?.restoryStory.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                  </a>
                </template>
                <div v-for="item in selectedStory?.restoryStory.attachments" :key="item.id">
                  <div style="display: flex;flex-direction: column;">
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 200px ;"
                    />
                    <p style="text-align: center;margin-bottom: 0;">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel> -->
                  <div v-else>Empty Restory Story</div>
                </a-form-item>
                <a-form-item label="Sentence">
                  <a-input placeholder="Sentence" :value="selectedStory?.sentence" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="Story">
                  <a-carousel
                    dots-class="slick-dots slick-thumb"
                    v-if="selectedStory?.attachments.length"
                  >
                    <template #customPaging="props">
                      <a v-if="selectedStory?.attachments[props.i].type === 'image'">
                        <img
                          :src="`${URL}/${selectedStory?.attachments[props.i].url}`"
                          :alt="selectedStory?.attachments[props.i].caption"
                          style="height: 25px ;"
                        />
                        <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                        <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                        <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                      </a>
                      <a v-else-if="selectedStory?.attachments[props.i].type === 'video'">
                        <video style="height: 25px;">
                          <source
                            :src="`${URL}/${selectedStory?.attachments[props.i].url}`"
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    </template>
                    <div v-for="item in selectedStory?.attachments" :key="item.id">
                      <div
                        style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                      >
                        <a-image
                          :src="`${URL}/${item.url}`"
                          :alt="item.caption"
                          style="height: 150px; width: 250px;"
                          v-if="item.type === 'image'"
                        />
                        <video
                          v-else-if="item.type === 'video'"
                          style="height: 150px; width: 250px;"
                          controls
                        >
                          <source :src="`${URL}/${item.url}`" type="video/mp4" />
                        </video>
                        <p style="text-align: center;margin-bottom: 0">{{ item.caption }}</p>
                      </div>
                    </div>
                  </a-carousel>
                  <!-- <a-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                v-if="selectedStory?.attachments.length"
              >
                <template #customPaging="props">
                  <a>
                    <img
                      :src="`${URL}/${selectedStory?.attachments[props.i].url}`"
                      :alt="selectedStory?.attachments[props.i].caption"
                      style="height: 25px ;"
                    />
                  </a>
                </template>
                <div v-for="item in selectedStory?.attachments" :key="item.id">
                  <div style="display: flex;flex-direction: column;">
                    <a-image
                      :src="`${URL}/${item.url}`"
                      :alt="item.caption"
                      style="height: 200px ;"
                    />
                    <p style="text-align: center;margin-bottom: 0;">{{ item.caption }}</p>
                  </div>
                </div>
              </a-carousel> -->
                  <div v-else>Empty Story</div>
                </a-form-item>
                <a-form-item label="Long Story">
                  <div v-if="selectedStory?.longstories">
                    <div v-for="(item, index) in selectedStory?.longstories" :key="index">
                      <a-card :title="`Story ${index + 1}`" style="width: 100%">
                        <a-form-item label="Restory Content">
                          <a-carousel
                            dots-class="slick-dots slick-thumb"
                            v-if="item.restoryContent"
                          >
                            <template #customPaging="props">
                              <a v-if="item.restoryContent.attachments[props.i].type === 'image'">
                                <img
                                  :src="`${URL}/${item.restoryContent.attachments[props.i].url}`"
                                  :alt="item.restoryContent.attachments[props.i].caption"
                                  style="height: 25px ;"
                                />
                                <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                                <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                                <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                              </a>
                              <a
                                v-else-if="
                                  item.restoryContent.attachments[props.i].type === 'video'
                                "
                              >
                                <video style="height: 25px;">
                                  <source
                                    :src="`${URL}/${item.restoryContent.attachments[props.i].url}`"
                                    type="video/mp4"
                                  />
                                </video>
                              </a>
                            </template>
                            <div
                              v-for="itemContent in item.restoryContent.attachments"
                              :key="itemContent.id"
                            >
                              <div
                                style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                              >
                                <a-image
                                  :src="`${URL}/${itemContent.url}`"
                                  :alt="itemContent.caption"
                                  style="height: 150px; width: 250px;"
                                  v-if="itemContent.type === 'image'"
                                />
                                <video
                                  v-else-if="itemContent.type === 'video'"
                                  style="height: 150px; width: 250px;"
                                  controls
                                >
                                  <source :src="`${URL}/${itemContent.url}`" type="video/mp4" />
                                </video>
                                <p style="text-align: center;margin-bottom: 0">
                                  {{ itemContent.caption }}
                                </p>
                              </div>
                            </div>
                          </a-carousel>
                          <!-- <a-carousel
                        arrows
                        dots-class="slick-dots slick-thumb"
                        v-if="item.restoryContent"
                      >
                        <template #customPaging="props">
                          <a>
                            <img
                              :src="`${URL}/${item.restoryContent.attachments[props.i].url}`"
                              :alt="item.restoryContent.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                          </a>
                        </template>
                        <div
                          v-for="itemContent in item.restoryContent.attachments"
                          :key="itemContent.id"
                        >
                          <div style="display: flex;flex-direction: column;">
                            <a-image
                              :src="`${URL}/${itemContent.url}`"
                              :alt="itemContent.caption"
                              style="height: 200px ;"
                            />
                            <p style="text-align: center;margin-bottom: 0;">
                              {{ itemContent.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel> -->
                          <div v-else>Empty Restory Content</div>
                        </a-form-item>
                        <a-form-item label="Restory Story">
                          <a-carousel dots-class="slick-dots slick-thumb" v-if="item.restoryStory">
                            <template #customPaging="props">
                              <a v-if="item.restoryStory.attachments[props.i].type === 'image'">
                                <img
                                  :src="`${URL}/${item.restoryStory.attachments[props.i].url}`"
                                  :alt="item.restoryStory.attachments[props.i].caption"
                                  style="height: 25px ;"
                                />
                                <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                                <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                                <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                              </a>
                              <a
                                v-else-if="item.restoryStory.attachments[props.i].type === 'video'"
                              >
                                <video style="height: 25px;">
                                  <source
                                    :src="`${URL}/${item.restoryStory.attachments[props.i].url}`"
                                    type="video/mp4"
                                  />
                                </video>
                              </a>
                            </template>
                            <div
                              v-for="itemStory in item.restoryStory.attachments"
                              :key="itemStory.id"
                            >
                              <div
                                style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                              >
                                <a-image
                                  :src="`${URL}/${itemStory.url}`"
                                  :alt="itemStory.caption"
                                  style="height: 150px; width: 250px;"
                                  v-if="itemStory.type === 'image'"
                                />
                                <video
                                  v-else-if="itemStory.type === 'video'"
                                  style="height: 150px; width: 250px;"
                                  controls
                                >
                                  <source :src="`${URL}/${itemStory.url}`" type="video/mp4" />
                                </video>
                                <p style="text-align: center;margin-bottom: 0">
                                  {{ itemStory.caption }}
                                </p>
                              </div>
                            </div>
                          </a-carousel>
                          <!-- <a-carousel
                        arrows
                        dots-class="slick-dots slick-thumb"
                        v-if="item.restoryStory"
                      >
                        <template #customPaging="props">
                          <a>
                            <img
                              :src="`${URL}/${item.restoryStory.attachments[props.i].url}`"
                              :alt="item.restoryStory.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                          </a>
                        </template>
                        <div v-for="itemStory in item.restoryStory.attachments" :key="itemStory.id">
                          <div style="display: flex;flex-direction: column;">
                            <a-image
                              :src="`${URL}/${itemStory.url}`"
                              :alt="itemStory.caption"
                              style="height: 200px ;"
                            />
                            <p style="text-align: center;margin-bottom: 0;">
                              {{ itemStory.caption }}
                            </p>
                          </div>
                        </div>
                      </a-carousel> -->
                          <div v-else>Empty Restory Story</div>
                        </a-form-item>
                        <a-form-item label="Contents">
                          <a-carousel
                            dots-class="slick-dots slick-thumb"
                            v-if="item.attachments.length"
                          >
                            <template #customPaging="props">
                              <a v-if="item.attachments[props.i].type === 'image'">
                                <img
                                  :src="`${URL}/${item.attachments[props.i].url}`"
                                  :alt="item.attachments[props.i].caption"
                                  style="height: 25px ;"
                                />
                                <!-- <img
                      :src="`${URL}/${selectedContent?.thumbnail}`"
                      :alt="selectedContent?.attachments[props.i].caption"
                      style="height: 25px ;"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->

                                <!-- <video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :poster="`${URL}/${selectedContent?.thumbnail}`"
                      controls
                      :volume="0.6"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    /> -->
                                <!-- <vue-core-video-player
                      :src="`${URL}/${selectedContent?.attachments[props.i].url}`"
                      :controls="true"
                      v-if="selectedContent?.attachments[props.i].type === 'video'"
                    ></vue-core-video-player> -->
                              </a>
                              <a v-else-if="item.attachments[props.i].type === 'video'">
                                <video style="height: 25px;">
                                  <source
                                    :src="`${URL}/${item.attachments[props.i].url}`"
                                    type="video/mp4"
                                  />
                                </video>
                              </a>
                            </template>
                            <div v-for="itemAttach in item.attachments" :key="itemAttach.id">
                              <div
                                style="display: flex;flex-direction: column; justify-content: center; align-items: center;"
                              >
                                <a-image
                                  :src="`${URL}/${itemAttach.url}`"
                                  :alt="itemAttach.caption"
                                  style="height: 150px; width: 250px;"
                                  v-if="itemAttach.type === 'image'"
                                />
                                <video
                                  v-else-if="itemAttach.type === 'video'"
                                  style="height: 150px; width: 250px;"
                                  controls
                                >
                                  <source :src="`${URL}/${itemAttach.url}`" type="video/mp4" />
                                </video>
                                <p style="text-align: center;margin-bottom: 0">
                                  {{ itemAttach.caption }}
                                </p>
                              </div>
                            </div>
                          </a-carousel>
                          <!-- <a-carousel
                        arrows
                        dots-class="slick-dots slick-thumb"
                        v-if="item.attachments.length"
                      >
                        <template #customPaging="props">
                          <a>
                            <img
                              :src="`${URL}/${item.attachments[props.i].url}`"
                              :alt="item.attachments[props.i].caption"
                              style="height: 25px ;"
                            />
                          </a>
                        </template>
                        <div v-for="itemAtt in item.attachments" :key="itemAtt.id">
                          <div style="display: flex;flex-direction: column;">
                            <a-image
                              :src="`${URL}/${itemAtt.url}`"
                              :alt="itemAtt.caption"
                              style="height: 200px ;"
                            />
                            <p style="text-align: center;margin-bottom: 0">{{ itemAtt.caption }}</p>
                          </div>
                        </div>
                      </a-carousel> -->
                          <div v-else>Empty Contents</div>
                        </a-form-item>
                        <a-form-item label="Comments">
                          <div v-if="item.lastComments.lenght">
                            <div v-for="itemComment in item.lastComments" :key="itemComment._id">
                              <div style="margin-bottom: 15px;">
                                <div
                                  style="display: flex; align-items: center; margin-bottom: 5px;"
                                >
                                  <a-avatar
                                    :src="`${URL}/${itemComment.creator.avatar_sm}`"
                                    v-if="itemComment.creator.avatar_sm"
                                  />
                                  <a-avatar
                                    :src="`${URL}/${itemComment.creator.avatar}`"
                                    v-else-if="itemComment.creator.avatar"
                                  />
                                  <a-avatar v-else>
                                    <template #icon><UserOutlined /></template
                                  ></a-avatar>
                                  <p
                                    style="margin-left: 5px; font-weight: 700; text-align: center;margin-bottom: 0;"
                                  >
                                    {{ itemComment.creator.username }}
                                  </p>
                                </div>
                                <p style="margin-bottom: 0;">
                                  {{ itemComment.comment }}
                                </p>
                                <div
                                  style="background-color: black; width: 100%; height: 1px;margin-top: 5px;"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div v-else>Empty Comment</div>
                        </a-form-item>
                      </a-card>
                    </div>
                  </div>
                  <div v-else>Empty Long Story</div>
                </a-form-item>
                <a-form-item label="Comments">
                  <div v-if="selectedStory?.lastComments.length">
                    <div v-for="(item, index) in selectedStory?.lastComments" :key="index">
                      <div style="margin-bottom: 15px;">
                        <div style="display: flex; align-items: center; margin-bottom: 5px;">
                          <a-avatar
                            :src="`${URL}/${item.creator.avatar_sm}`"
                            v-if="item.creator.avatar_sm"
                          />
                          <a-avatar
                            :src="`${URL}/${item.creator.avatar}`"
                            v-else-if="item.creator.avatar"
                          />
                          <a-avatar v-else>
                            <template #icon><UserOutlined /></template
                          ></a-avatar>
                          <p
                            style="margin-left: 5px; font-weight: 700; text-align: center;margin-bottom: 0;"
                          >
                            {{ item.creator.username }}
                          </p>
                        </div>
                        <p style="margin-bottom: 0;">
                          {{ item.comment }}
                        </p>
                        <div
                          style="background-color: black; width: 100%; height: 1px;margin-top: 5px;"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div v-else>Empty Comment</div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, reactive, toRefs } from '@vue/runtime-core'
import { UserOutlined, LeftOutlined } from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref } from 'vue'
// const DaftarTopup = defineAsyncComponent(() => import('@/components/user/MyTopup'))
// const DaftarTransaksi = defineAsyncComponent(() => import('@/components/user/MyTransaction'))
// const AccountDetails = defineAsyncComponent(() => import('@/components/user/MyDetails'))
// const DaftarPost = defineAsyncComponent(() => import('@/components/user/MyPosts'))
const URL = process.env.VUE_APP_BACKEND_URL
const menus = [
  {
    name: 'Detail Akun',
    total: 0,
    addition: 'detailAccount',
    icon: 'fe fe-user',
  },
  {
    name: 'Daftar Post',
    total: 0,
    addition: 'post',
    icon: 'fe fe-bookmark',
  },
  {
    name: 'Daftar Deposit/Withdrawal',
    total: 0,
    addition: 'course',
    icon: 'fe fe-dollar-sign',
  },
  {
    name: 'Daftar Transaksi',
    total: 0,
    addition: 'transaksi',
    icon: 'fe fe-shopping-cart',
  },
]
export default {
  components: {
    // DaftarTopup,
    // DaftarTransaksi,
    // AccountDetails,
    // DaftarPost,
    UserOutlined,
    LeftOutlined,
  },
  props: {
    typePost: {
      type: String,
      default: 'content',
    },
  },

  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    let state = reactive({
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      menuActive: 'Daftar Transaksi',
      akun: {
        _id: route.params.id,
        firstName: '',
        lastName: '',
        email: '',
        profilePicture: '',
        myWishlists: [],
      },
    })
    const selectedContent = ref({})
    const selectedStory = ref({})
    const loading = ref(true)

    const onChangeMenu = menu => {
      state.menuActive = menu
      if (route.params.post) router.push('/account/detail/' + route.params.id)
    }

    const fetchDetail = async () => {
      loading.value = true
      const id = route.params.id
      if (route.params.post) state.menuActive = 'Daftar Post'
      // state.akun = await store.dispatch('akun/FETCH_DETAIL_AKUN', id)
      // if (store.state.akun?.accounts?.length > 1) {
      //   console.log(store.state.akun.accounts)
      //   state.akun = store.state.akun.accounts.filter(el => el._id == route.params.id)[0]
      // } else {
      const apiFetchDetailAkun = await store.dispatch('akun/ADMIN_FETCH_USER_DETAIL', { _id: id })
      state.akun = apiFetchDetailAkun.data
      if (!store.state.akun.contents.length || !store.state.akun.stories.length) {
        await store.dispatch(
          `akun/ADMIN_FETCH_USER_${
            route.params.typePost
              ? route.params.typePost.toUpperCase()
              : props.typePost.toUpperCase()
          }`,
          {
            _id: id,
          },
        )
      }
      if (route.params.typePost === 'content' || props.typePost === 'content') {
        selectedContent.value = store.state.akun.contents.find(el => el._id === route.params.post)
      } else if (route.params.typePost === 'story' || props.typePost === 'story') {
        selectedStory.value = store.state.akun.stories.find(el => el._id === route.params.post)
      }
      loading.value = false
      //
      // console.log(state.akun.firstName, 'akun')
    }
    const handleClickBack = () => {
      router.back()
    }

    onMounted(() => {
      fetchDetail()
      console.log(router.currentRoute)
    })
    return {
      state,
      menus,
      onChangeMenu,
      fetchDetail,
      URL,
      selectedContent,
      selectedStory,
      loading,
      handleClickBack,
    }
  },
}
</script>

<style lang="scss" module>
@import '../style.module.scss';
</style>

<style>
.slick-dots-to-bottom {
  bottom: 0 !important;
}
</style>
